body {
  overflow: hidden;
}
.skills-title {
  margin: 0;
  padding: 0;
  color: white;
  -webkit-animation: slide-in-left 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
  animation: slide-in-left 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}

.skills-section {
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: -60px;
  overflow: auto; /* This will allow the content to scroll within the container if needed */
}

.title {
  padding: 0;
  margin: 0;
  margin-bottom: 20px;
}

.skills {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  color: white;
  -webkit-animation: slide-in-left 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) 0.2s both;
  animation: slide-in-left 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) 0.2s both;
  overflow: auto; /* Enable scrolling if skills overflow the container */
}

.logo-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 15px;
  width: 100%; /* Set width to 100% to center align the logos horizontally on mobile */
  max-width: 250px; /* Limit the maximum width of the column */
}

.logo {
  width: 150px;
  height: 150px;
  margin-bottom: 10px;
}

.skills-title {
  color: white;
  font-size: 2rem;
  margin-bottom: 20px;
}

span {
  margin-bottom: 20px;
  font-size: 2rem;
}

@media (min-width: 768px) {
  /* CSS for screens wider than 768px (tablets and desktop) */
  .skills-section {
    align-items: center; /* Center align the content vertically on desktop */
  }

  .logo-wrapper {
    width: 33.33%; /* Set width to 33.33% to fit three columns on tablets and desktop */
  }
}

/* Add custom scrollbar for the skills section */
.skills-section::-webkit-scrollbar {
  width: 8px;
}

.skills-section::-webkit-scrollbar-track {
  background: transparent;
}

.skills-section::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 4px;
}

.skills-section::-webkit-scrollbar-thumb:hover {
  background: #555;
}

@-webkit-keyframes slide-in-left {
  0% {
    -webkit-transform: translateX(-1000px);
    transform: translateX(-1000px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    opacity: 1;
  }
}

@keyframes slide-in-left {
  0% {
    -webkit-transform: translateX(-1000px);
    transform: translateX(-1000px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    opacity: 1;
  }
}
