.main {
  position: relative;
  overflow: hidden;
  height: 100vh;
}

.video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  opacity: 0.5;
}

.introduction {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  max-width: 960px;
  width: 90%;
  padding: 0 15px;
  text-align: center;
  color: white;
}

.name {
  font-size: 3.5rem;
  margin-bottom: 20px;
  animation: slide-in-left 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}

.paragraph {
  font-size: 1.5rem;
  line-height: 1.6;
  margin-bottom: 40px;
  animation: slide-in-left 0.75s cubic-bezier(0.25, 0.46, 0.45, 0.94) 0.2s both;
}

.resume-btn-container {
  display: flex;
  justify-content: center;
  overflow: auto;
  scrollbar-width: thin;
  scrollbar-color: #888888 #ffffff;
}

.resume-btn {
  padding: 14px 30px;
  background-color: white;
  color: black;
  border: none;
  border-radius: 4px;
  font-size: 1.25rem;
  font-weight: 500;
  text-decoration: none;
  transition: background-color 0.2s ease-out;
  animation: slide-in-left 0.75s cubic-bezier(0.25, 0.46, 0.45, 0.94) 0.4s both;
}

.resume-btn:hover {
  background-color: #7a94b1;
  cursor: pointer;
}

/* Additional Styles for Mobile View */
@media (max-width: 767px) {
  .name {
    font-size: 2.5rem;
  }

  .paragraph {
    font-size: 1.2rem;
    line-height: 1.4;
  }

  .resume-btn {
    font-size: 1rem;
    padding: 10px 20px;
  }

  .resume-btn-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    overflow: auto;
  }
}
