* {
  font-family: 'Lato', sans-serif;
}

.main-content {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.email-form {
  background: rgb(2, 0, 36);
  background: linear-gradient(0deg, rgba(2, 0, 36, 0.8) 0%, rgba(10, 14, 61, 0.8) 100%);
  border-radius: 10px;
  box-shadow: 0px 0px 20px rgba(255, 255, 255, 0.1);
  padding: 4rem 9rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  animation: slide-in-left 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}

h2 {
  font-size: 2rem;
  margin-bottom: 2rem;
  color: white;
}

label {
  display: flex;
  text-align: left;
  font-size: 1.2rem;
  margin-top: 1.5rem;
  color: white;
}

input[type='text'],
input[type='email'],
textarea {
  width: 100%;
  padding: 1rem;
  border-radius: 5px;
  border: none;
  margin-top: 0.5rem;
  font-size: 1.1rem;
}

input[type='submit'] {
  background-color: #FFF;
  color: #474E68;
  font-size: 1.2rem;
  font-weight: bold;
  padding: 1rem 2rem;
  border-radius: 5px;
  border: none;
  margin-top: 2rem;
  cursor: pointer;
}

input[type='submit']:hover:not([disabled]) {
  background-color: #474E68;
  color: #FFF;
}

/* Media Queries */
@media screen and (max-width: 768px) {
  .email-form {
    padding: 2rem;
  }

  label {
    margin-top: 1rem;
  }

  input[type='submit'] {
    margin-top: 1.5rem;
  }
  
  /* Center content in mobile view */
  .email-form {
    align-items: center;
    justify-content: center;
  }
  
  /* Adjust form width on mobile */
  input[type='text'],
  input[type='email'],
  textarea {
    max-width: 100%;
    box-sizing: border-box;
  }
}

@-webkit-keyframes slide-in-left {
  0% {
    -webkit-transform: translateX(-1000px);
            transform: translateX(-1000px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateX(0);
            transform: translateX(0);
    opacity: 1;
  }
}
@keyframes slide-in-left {
  0% {
    -webkit-transform: translateX(-1000px);
            transform: translateX(-1000px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateX(0);
            transform: translateX(0);
    opacity: 1;
  }
}
