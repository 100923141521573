.main-header {
  width: 100vw;
  height: 7vh;
  display: flex;
  justify-content: center;
  align-items: center;

  background: rgba(0, 0, 0, 0.5);
  color: black;
  box-shadow: 0px 0px 20px rgba(255, 255, 255, 0.1);
//   border-bottom: 2px solid white;
}

.nav-links {
  display: flex;
  align-items: center;
  list-style: none;
  margin: 0;
  padding: 0;
  
}

.nav-link {
  margin: 0 20px;
  font-size: 18px;
  color: white;
  text-decoration: none;
  font-size: 1.5rem;
}

.nav-link:hover {
  color: #b7c5d8;
}
